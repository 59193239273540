.proActarSelection {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 575px) {
    width: 75%;
    height: auto;
  }
  .uploadingWrapper {
    background: #fff;
    display: block;
    width: 450px;
    height: 450px;
    border-radius: 20px;
    position: relative;
    .closeme {
      position: absolute;
      right: 15px;
      top: 15px;
      line-height: 0.5;
      font-size: 20px;
      font-weight: 500;
      color: #272a3b;
      cursor: pointer;
    }
    @media screen and (max-width: 575px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      height: auto;
      padding: 30px 0;
    }
  }

  .uploadingWrapper .row {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100% !important;
    gap: 30px;
  }

  .uploadingImage {
    text-align: center;
    border: 3px solid #2693e8;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    margin: auto;
    @media screen and (max-width: 575px) {
      width: 70px;
      height: 70px;
    }
  }

  .uploadContant {
    text-align: center;
    margin-top: 25px;
    @media screen and (max-width: 575px) {
      margin-top: 15px;
    }
  }

  .uploadContant p {
    font-size: 17px;
    font-weight: 300;
    @media screen and (max-width: 575px) {
      font-size: 14px;
    }
  }

  .uploadingImage img {
    width: 65px;
    @media screen and (max-width: 575px) {
      width: 36px;
    }
  }
}
