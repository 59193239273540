.outer-home{
    position: relative;
    &:before {
        background: #000;
        content: "";
        height: 100%;
        left: 0;
        z-index: 1;
        opacity: .7;
        position: fixed;
        top: 0;
        width: 100%;
    }
}
.infoHome{
    background-color: #272a3b;
    border-radius: 25px;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 35%);
    height: 471px;
    position: relative;
    padding: 25px;
    @media screen and (max-width:390px) {
        padding: 25px 15px!important;
    }
    position: fixed;
    z-index: 1000;
    top: 50%;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, -50%);
    button.btn-all.btn-add{
        position: absolute;
    top: 8px;
    background: transparent;
    z-index: 1;
    right: 10px;
    color: #fff;
    border: none;
    width: 20px;
    height: 20px;
    font-size: 18px;
    display: flex;
    align-items: center;
    box-shadow: rgb(0 0 0 / 20%) 0px 18px 50px -10px;
    justify-content: center;
    cursor: pointer;
    }
    button.dmd-btn {
        position: absolute;
        bottom: 25px;
        transform: translateX(-50%);
        left: 50%;
        margin: 0;
        @media screen and (max-width:992px) {
            width: 60%;
    border-radius: 20px;
    height: auto;
    color: #fff;
    padding: 10px 0;
    font-weight: 500;
        }
    }
    .InfoMessageSection {
        height: 100%;
        overflow-y: auto;
        color: #fff;
        h2{
            padding-bottom: 15px!important;
            text-align: center;
            color: #fff;
        }
        h1 {
            padding-bottom: 10px;
        }
        @media screen and (max-width:992px) {
            p {
                font-size: 14px;
            }
        }
        b{
            margin-bottom: 5px;
        }
        p{
            font-size: 16px;
            margin-top: 6px;
        }
    }
    @media screen and (min-width:992.5px) {
        width: 819px;
    }
    @media screen and (max-width:992px) {
        width: 90%;
    }
}
