// MIXIN
@mixin heading-one {
    font-size: 25px;
}

@mixin heading-three {
    font-size: 15px;
}

@mixin heading-five {
    font-size: 15px;
}

@mixin heading-four {
    font-size: 22px;
}

@mixin para-normal {
    font-size: 12px;
}

@mixin application-wrap {
    background-color: $bgcolor;
    min-height: 100vh;
    position: relative;

    @media screen and (min-width:475px) {
        /*display: none !important;*/
    }
}

@mixin app_load {
    text-align: center;
    padding-top: 97px;
}

@mixin continue-button {
    background: $btnbg;
    color: $black;
    border-radius: 50%;
    border: none;
    height: 88px;
    width: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 800;
    margin: auto;
    text-align: center;
    margin-top: 9.7%;

    &:hover {
        text-decoration: none;
        color: $black;
    }
}

@mixin continue-button-wide {
    background: $btnbg;
    color: $white;
    border-radius: 11px;
    border: none;
    padding: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: bold;
    margin: auto;
    text-align: center;
    margin-top: 23px;
    cursor: pointer;

    &:hover {
        text-decoration: none;
        color: $black;
    }
}

@mixin place-feild {
    max-width: 328px;
    height: 46px;
    background: $inputbg;
    border: 1px solid $borderColor;
    margin: auto;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-clip: padding-box;
    border-radius: 1rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &:focus {
        box-shadow: none;
        outline: none;
        border-color: $borderColor;
        background: $inputbg;
    }
}

@mixin mail-input {
    color: $white;
    font-size: 25.5px;
    font-weight: bold;
    padding: 105.5px 0 40px 0;
    text-align: center;
}

@mixin take-tour {
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
    color: $white;
    text-decoration-thickness: 0;
    text-underline-position: from-font;
}

@mixin enter-code {
    text-align: center;
    color: $white;
    font-weight: bold;
    font-size: 18px;
    text-transform: none;
}

@mixin tour-link {
    text-align: center;
    position: absolute;
    bottom: 3.5%;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
}

@mixin red-txt {
    text-align: center;
    color: $red;
    font-size: 18px;
    margin-bottom: 22px;
}

@mixin padding-for {
    @media screen and (max-width:390px) {
        padding-left: 27px;
        padding-right: 27px;
    }

    @media screen and (min-width:342px) {
        max-width: 354px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

@mixin padding-for-nav {
    @media screen and (max-width:370px) {
        padding-left: 19px;
        padding-right: 19px;
    }

    @media screen and (min-width:342px) {
        max-width: 342px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

@mixin form-error {
    display: block;
    color: $formerror;
}

// Desktop View Applied from 13-07-2022


@mixin desktop-continue-button {
    background: $btnbg;
    color: $black;
    border-radius: 82px;
    display: inherit;
    margin: auto;
    height: auto;
    padding: 14.5px;
    width: 225px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 800;
    margin: auto;
    text-align: center;
    margin-top: 46px;

    &:hover {
        text-decoration: none;
        color: $black;
    }
}

@mixin desktop-continue-button-wide {
    background: $btnbg;
    color: $black;
    border-radius: 11px;
    border: none;
    padding: 12px;
    width: 275px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: bold;
    margin: auto;
    text-align: center;
    margin-top: 23px;

}


@mixin padding-for-nav {

    @media screen and (min-width:342px) {
        max-width: 342px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width:1366px) and (min-width:700px) {
        max-width: 1200px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (min-width:1366px) {
        max-width: 96%;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

// MIXIN ENDS