@import "/src/common/abstracts/variable.scss";
@import "/src/common/abstracts/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.spinner-loads {
  position: relative;
  &::before {
    background-image: url(../../../../public/assets/images/spinner-loading.svg);
    background-size: cover;
    content: "";
    display: block;
    height: 100px;
    left: 50%;
    overflow: visible;
    position: fixed;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    visibility: visible;
    width: 100px;
    z-index: 99999999;
    @media screen and (max-width: 768px) {
      height: 70px;
      width: 70px;
    }
  }
}





.accountsettings_bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  // top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
    background-color: rgb(0, 0, 0);
  }
  .accountsettings {
    // width: 60%;
    width: 56.5%;
    min-width: 200px;
    max-height: 583px;
    @media screen and (max-width: 415px) {
      max-height: 550px;
      top:36px
    }
    position: relative;
    overflow-y: auto;
    // border-radius: 25px;
    border-radius: 55px;
    background-color: #a6a6a6;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 25px;
    &::-webkit-scrollbar {
      background-color: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: none;
    }

    &::-webkit-scrollbar {
      width: 0;
    }
    @media screen and (max-width: 390px) {
      padding: 25px 15px !important;
    }
    z-index: 1000;
    .avatar {
      width: 81.5%;
      margin: 0 auto;
      label {
        color: #222831;
        @media screen and (max-width: 768px) {
          font-size: 13px;
        }
        & + input.form-control {
          margin-bottom: 6px !important;
          color: #e9e9e9;
          @media screen and (max-width: 768px) {
            font-size: 12.5px;
            margin-bottom: 4px !important;
          }
        }
      }
      h2 {
        color: #222831;
        margin-bottom: 15px;
        @media screen and (max-width: 415px) {
          margin-bottom: 10px;
        }
        font-weight: 500;
      }
    }
    .update .avatar_row {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1075px) and (min-width: 768.5px) {
        justify-content: center !important;
        gap: 5%;
      }

      button.dmd_btn {
        margin-top: 30px !important;
        background: #9dbbae;
        border: none;
        border-radius: 82px;
        color: #000;
        color: #fff;
        cursor: pointer;
        display: inherit;
        display: flex;
        height: auto;
        justify-content: center;
        margin: 40px auto auto;
        padding: 14.5px;
        text-align: center;
        width: 225px;
        @media screen and (max-width: 1075px) and (min-width: 768.5px) {
          width: 148px;
        }
      }
    }
    form input {
      @include place-feild();
      border: 2.5px solid #ffd966;
      max-width: 391px;
      height: 38px;
      border-radius: 8px;
      margin-left: 0;
      margin-bottom: 2px;
      margin-top: 2px;
      &:focus {
        border-color: #ffd966;
      }
    }
  }

  .private_avatar {
    @media screen and (min-width: 1200px) and (max-width: 1360px) {
      margin-bottom: 0;
    }
    label {
      display: block;
      position: relative;
      padding-left: 0;
      margin-bottom: 20px;
     
      font-size: 15px;
      -webkit-user-select: none;
      user-select: none;
      color: #222831;
      &:nth-child(2) {
        @media screen and (max-width: 768px) {
          text-align: center;
        }
      }
      &:first-child {
        margin-left: 35px;
        top: 5px;
      }
    }
    .checkmark {
      position: absolute;
      top: -2px;
      left: -35px;
      height: 23px;
      width: 23px;
      background-color: transparent;
      box-shadow: inset -0.5px -0.5px 3px 2px #00000080,
        0px 0px 0px 1px #00000080;
      border: 2.5px solid #ffd966;
      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
    label input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ .checkmark {
        background-color: #9dbbae;
        &:after {
          display: block;
        }
      }
    }
    .avatar_wrapper {
      .uploadIcon img {
        border-radius: 0;
    }
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
      grid-gap: 15px;
      max-width: 590px;
      margin: auto;
      height: 151px;
      overflow-y: auto;
      &::-webkit-scrollbar-thumb {
        background-color: #9dbbae !important;
        // border-radius: 0 !important;
      }
      @media screen and (max-width: 768px) {
        height: 120px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-evenly;
      }
      
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          width: 45px;
          height: 45px;
        }
      }
      .active {
        span.over_icon {
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
          opacity: 1;
          transition: 0.4s;
        }
        &::before {
          content: "";
          background-color: #9dbbae50;
          position: absolute;
          width: 60px;
          height: 60px;
          transform: scale(0.85) !important;
          top: 0;
          opacity: 1;
          left: 0;
          border-radius: 50%;
          transition: 0.4s;
          cursor: pointer;
          @media screen and (max-width: 768px) {
            width: 45px;
            height: 45px;
          }
        }
      }
      div {
        // &:hover span.over_icon{
        //     top: 50%;
        //     opacity: 1;
        // }
        // &:hover::before{
        //     // transform: scale(1);
        //     opacity: 1;
        // }
        span.over_icon {
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 0;
          opacity: 0;
          transition: 0.4s;
        }
        position: relative;
        &::before {
          content: "";
          background-color: #00000080;
          position: absolute;
          width: 60px;
          height: 60px;
          transform: scale(0);
          opacity: 0;
          top: 0;
          left: 0;
          border-radius: 50%;
          transition: 0.4s;
          cursor: pointer;

          @media screen and (max-width: 768px) {
            width: 45px;
            height: 45px;
          }
        }
      }
    }
  }
}

.avatar_row {
  button {
    font-weight: normal;
    text-transform: capitalize;
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .accountsettings_bg .accountsettings .avatar {
    width: 100%;
  }
  .fixed-nav .nav {
    z-index: 0;
  }
  .accountsettings_bg {
    .accountsettings {
      width: 85%;
      border-radius: 25px;
      .update .avatar_row {
        justify-content: center;
        gap: 15px;
        button.dmd_btn {
          width: 100%;
          border-radius: 8px;
          height: auto;
          padding: 10px 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 999px) {
  .private_avatar {
    margin-bottom: 15px !important;
  }
  .update .avatar_row {
    // flex-direction: column-reverse;
    // margin-top: 15px;
    button {
      margin-top: 15px;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1359px) {
  .accountsettings_bg .accountsettings {
    padding-left: 0;
    padding-right: 0;
  }
}
.accountsettings_bg .private_avatar .avatar_wrapper {
  div img {
    border: 4px solid transparent;
  }
  .active {
    img {
      border: 4px solid #ffd966;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      -khtml-border-radius: 50%;
      display: inline-block;
    }
  }
}
.fade_header {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
}
// .accountSettings_avatar_row__j9JmH .col-6 {
//     width: 50%;
// }

.termscheck{
  // display: none!important;
  & ~ label {
    display: inline-block;
    left: 0;
    width: 23px;
    height: 23px;
    background-color: #9dbbae;
    border: 2.5px solid #ffd966;
    box-shadow: inset -0.5px -0.5px 3px 2px rgba(0, 0, 0, 0.5019607843), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.5019607843);
    cursor: pointer;
  }
  
  &:checked + label {
    background-color: #9dbbae;
  }
  
  /* Add a checkmark icon */
  label:after {
    content: "";
    position: absolute;
    left: 7.5px;
    top: -4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  
  /* Show the checkmark when checkbox is checked */
  &:checked + label:after {
    display: block;
    content: "";
    position: absolute;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}
.accountsettings_bg{
  .accountsettings{
    label.container{
      position: relative;
    }
  }
}
.newexperience{
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  p{
    a {
      color: #222831;
      font-weight: bold;
      text-decoration: underline;
      text-underline-position: under;
  }
  }
  input{
    max-width: none!important;
    display: none!important;
  }
}

.profile_error_validation{
  display: flex;
  flex-direction: column;
}