
// VARAIBLES

$bgcolor:#272a3b;
$btnbg:#9DBBAE;
$black:#000;
$white:#fff;
$inputbg:#222831;
$borderColor:#435364;
$red:#D36135;
$herobg:#233831;
$carauselbg:#151726;
$lightgrey: #72737c;
$lightgreyone: #b8b9bd;
$greanhead: #549F84;
$formerror:#D50037;
$blue:#0070c0;
$yellow:#ffc000;

// VARIABLES ENDS